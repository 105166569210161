body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}

body,
html,
#root {
  height: 100%;
}

#root {
  overflow: auto;
}
